<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-col>
    <div class="headline">{{ $attrs.typeSelected.subtitle }}</div>
    <v-btn text color="cancel" @click="loadTypes">
      <v-icon>reply</v-icon>Indietro/Back</v-btn
    >
    <v-divider></v-divider>
    <v-skeleton-loader
      v-if="loadingFields"
      class="mx-auto"
      type="card"
      :loading="true"
    ></v-skeleton-loader>
    <v-form v-if="!loadingFields" ref="form" :lazy-validation="true">
      <span v-for="(field, index) in requestModelFields" v-bind:key="index">
        <v-text-field
          v-if="
            field.jiraSchema.type === 'string' &&
              field.fieldId != 'description' &&
              !field.description.toLowerCase().includes('mail')
          "
          v-model="requestModelData[field.fieldId]"
          :label="field.name"
          :placeholder="field.description"
          required
          :rules="rules"
        ></v-text-field>
        <v-text-field
          v-if="
            field.jiraSchema.type === 'string' &&
              field.fieldId != 'description' &&
              field.description.toLowerCase().includes('mail')
          "
          v-model="requestModelData[field.fieldId]"
          :label="field.name"
          :placeholder="field.description"
          required
          :rules="emailRules"
        ></v-text-field>
        <v-alert
          v-if="
            field.jiraSchema.type === 'string' &&
              field.fieldId != 'description' &&
              field.description.toLowerCase().includes('mail')
          "
          dense
          text
          type="warning"
        >
          Attenzione! <strong>non utilizzare indirizzi PEC</strong>
        </v-alert>
        <v-textarea
          v-if="
            field.jiraSchema.type === 'string' &&
              field.fieldId === 'description'
          "
          v-model="requestModelData[field.fieldId]"
          :label="field.name"
          :placeholder="field.description"
          required
          :rules="rules"
        ></v-textarea>
        <v-select
          v-if="field.jiraSchema.type === 'option'"
          :items="field.validValues"
          v-model="requestModelData[field.fieldId]"
          item-text="label"
          item-value="value"
          :placeholder="field.description"
          :label="field.name"
          required
          :rules="rules"
        ></v-select>
        <v-menu
          v-if="field.jiraSchema.type === 'date'"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="requestModelData[field.fieldId]"
              :label="field.name"
              :rules="rules"
              :placeholder="field.description"
              prepend-icon="event"
              readonly
              v-bind="attrs"
              v-on="on"
              required
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="requestModelData[field.fieldId]"
          ></v-date-picker>
        </v-menu>
      </span>
      <v-file-input
        v-model="attachments[0]"
        placeholder=""
        label="Allega documenti/Attachments"
        prepend-icon="mdi-paperclip"
        :show-size="1000"
        :rules="fileRules"
      >
        <template v-slot:selection="{ text }">
          <v-chip small label color="primary">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <v-alert dense text type="warning">
        Attenzione! <strong>non è possibile</strong> caricare file più
        <strong>grandi di 10 MB</strong>.<br />
        Nel caso di <strong>più file</strong> da allegare è necessario creare
        <strong>un unico file zip</strong> ed allegarlo
      </v-alert>
      <v-switch
          v-model="checkAutorizzo"
          label="* Autorizzo l'invio all'indirizzo di posta elettronica da me indicato di comunicazioni tecniche di servizio legate all'utilizzo del portale e dei servizi in esso disponibili"
          color="info"
      ></v-switch>
      <v-switch
          v-model="checkVisione"
          color="info"
          label="* Dichiaro di aver preso visione dell'informativa fornita dal Titolare del trattamento ai sensi dell'art. 13 del Regolamento UE 2016/679 sulla protezione dei dati personali"
      ></v-switch>
      <a
        @click="showInformativa=true">
        <v-icon start>info</v-icon>
        Mostra informativa
      </a>
      <v-dialog
          v-model="showInformativa"
          width="auto"
      >
        <v-card>
          <v-card-text>
            Ai sensi dell'articolo 13 del Regolamento Europeo N. 679/2016 (GDPR) ti informiamo che:
            <ul>
              <li>1) i dati personali conferiti tramite il presente modulo (indirizzo e-mail) saranno trattati
                da Cineca con la sola finalità di gestire la tua richiesta di supporto/ segnalazione di un’anomalia.
                Tali dati saranno conservati per il tempo necessario all’espletamento delle finalità connesse al trattamento.
                Una volta terminata la gestione della sua richiesta/segnalazione, questa verrà mantenuta per futuri riferimenti e, in
                forma aggregata, per fini statistici volti alla misurazione della soddisfazione degli utenti e del miglioramento del
                servizio di supporto.</li>
              <li>2) il conferimento dei dati è obbligatorio per poter dar corso alla tua richiesta/segnalazione.
                Titolare del trattamento è Cineca, Via Magnanelli, 6/3, Casalecchio di Reno; il Responsabile della Protezione dei
                dati può essere contattato al seguente indirizzo e-mail: privacy@cineca.it. Potrai esercitare i diritti di cui agli artt.
                da 15 a 21 del GDPR (accesso, correzione, cancellazione, opposizione al trattamento dei dati) tramite comunicazione via
                e-mail a: privacy@cineca.it;</li>
              <li>3) i tuoi dati personali non verranno in alcun modo comunicati a terzi, verranno  e saranno utilizzati secondo
                la normativa vigente sulla privacy.</li>
          </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="showInformativa = false">Chiudi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider inset></v-divider>
      <v-btn color="cancel" tile large @click="loadTypes">
        <v-icon left>reply</v-icon> Indietro/Cancel</v-btn
      >
      <v-btn color="info" tile large @click="submitRequest" :disabled="!checkVisione || !checkAutorizzo"
        ><v-icon left>send</v-icon> Invia richiesta/Send request</v-btn
      >
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";

import VueJwtDecode from "vue-jwt-decode";
import router from "../router";

import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: window.config.G_SITE_KEY });

export default {
  name: "RequestForm",
  created() {
    this.loadFields();
  },
  methods: {
    loadTypes: function() {
      this.$emit("loadTypes");
    },
    loadFields: function() {
      this.$data.loadingFields = true;
      axios
        .get(
          "/api/v1/get/requestTypes/" + this.$attrs.typeSelected.id + "/fields",
          {
            headers: {
              "x-auth-token": sessionStorage.getItem("jwt_token"),
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0"
            }
          }
        )
        .then(response => {
          this.$data.requestModelFields = response.data;
          let emailFieldNotFound = true;
          for (let i = 0; i < response.data.length; i++) {
            console.log(response.data[i]);
            let fieldId = response.data[i].fieldId;
            if (fieldId === "customfield_11207") {
              emailFieldNotFound = false;
            }
          }
          if (emailFieldNotFound) {
            let emailField = {
              fieldId: "customfield_11207",
              name: "Email Richiedente",
              description: "Indirizzo email richiedente",
              required: false,
              validValues: [],
              jiraSchema: { type: "string" }
            };
            response.data.unshift(emailField);
          }
          for (let i = 0; i < response.data.length; i++) {
            this.$data.requestModelData[response.data[i].fieldId] = null;
            this.$data.requestModelTypes[response.data[i].fieldId] =
              response.data[i].jiraSchema.type;
            if (this.$data.requestModelFields[i].name === "Email Richiedente") {
              this.$data.requestModelFields[i].name =
                "Email Richiedente/Requestor Email";
              this.$data.requestModelFields[i].description =
                "Indirizzo email richiedente/Requestor email address";
            }
            if (this.$data.requestModelFields[i].name === "Oggetto") {
              this.$data.requestModelFields[i].name = "Oggetto/Subject";
              this.$data.requestModelFields[i].description =
                "Specifica l'oggetto/Please specify subject";
            }
            if (this.$data.requestModelFields[i].name === "Descrizione") {
              this.$data.requestModelFields[i].name = "Descrizione/Body";
              this.$data.requestModelFields[i].description =
                "Specifica la descrizione della richiesta/Please specify request body";
            }
            if (this.$data.requestModelFields[i].name === "Gravità") {
              this.$data.requestModelFields[i].name = "Gravità/Gravity";
              for (
                let idx = 0;
                idx < this.$data.requestModelFields[i].validValues.length;
                idx++
              ) {
                if (
                  this.$data.requestModelFields[i].validValues[idx].label ===
                  "Trivial"
                ) {
                  this.$data.requestModelFields[i].validValues[idx].label =
                    "Bassa/Trivial";
                }
                if (
                  this.$data.requestModelFields[i].validValues[idx].label ===
                  "Medium"
                ) {
                  this.$data.requestModelFields[i].validValues[idx].label =
                    "Media/Medium";
                }
                if (
                  this.$data.requestModelFields[i].validValues[idx].label ===
                  "Critical"
                ) {
                  this.$data.requestModelFields[i].validValues[idx].label =
                    "Critica/Critical";
                }
                if (
                  this.$data.requestModelFields[i].validValues[idx].label ===
                  "Blocker"
                ) {
                  this.$data.requestModelFields[i].validValues[idx].label =
                    "Bloccante/Blocker";
                }
              }
            }
            if (this.$data.requestModelFields[i].name === "Urgenza") {
              this.$data.requestModelFields[i].name = "Urgenza/Urgency";
            }
            if (this.$data.requestModelFields[i].name === "Data Fabbisogno") {
              this.$data.requestModelFields[i].name =
                "Data Fabbisogno/Due Date";
              this.$data.requestModelFields[i].description =
                "Data Fabbisogno/Due Date";
            }
          }
          this.$data.loadingFields = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status);
            if (error.response.status === 410) {
              router.push({ name: "sessionExpired" });
            } else {
              router.push({ name: "error" });
            }
          } else {
            router.push({ name: "error" });
          }
        });
    },
    submitRequest: async function() {
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("jiraRequestForm");
      let validation = this.$refs.form.validate();
      if (!validation) {
        this.$data.text = "La richiesta non è completa/Request is incomplete";
        this.$data.snackbar = true;
        return;
      }
      let formData = new FormData();
      let decodedJwt = VueJwtDecode.decode(sessionStorage.getItem("jwt_token"));
      let reporter = null;
      let payload = {
        serviceDeskId: decodedJwt.serviceDeskId,
        requestTypeId: this.$attrs.typeSelected.id,
        requestFieldValues: {
          components: [{ id: decodedJwt.componentId }]
        }
      };
      for (let i = 0; i < this.requestModelFields.length; i++) {
        let field = this.requestModelFields[i];
        if (field.jiraSchema.type === "option") {
          payload.requestFieldValues[field.fieldId] = {
            id: this.requestModelData[field.fieldId]
          };
        } else {
          if (field.name === "Email Richiedente/Requestor Email") {
            reporter = this.requestModelData[field.fieldId];
          }
          payload.requestFieldValues[field.fieldId] = this.requestModelData[
            field.fieldId
          ];
        }
      }

      formData.append("payload", JSON.stringify(payload));
      formData.append("reporter", reporter);
      formData.append("g-recaptcha-v3-token", token);
      if (this.attachments != null && this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          formData.append("files", this.attachments[i]);
        }
      }
      axios
        .post("/api/v1/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": sessionStorage.getItem("jwt_token"),
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0"
          }
        })
        .then(response => {
          router.push({ name: "feedback", params: { uid: response.data.uid } });
        })
        .catch(function() {
          router.push({ name: "error" });
        });
    }
  },
  data: () => ({
    checkVisione: false,
    checkAutorizzo: false,
    showInformativa: false,
    rules: [
      v => !!v || "Campo obbligatorio/Field mandatory",
      v =>
        (v && v.trim().length > 0) ||
        "Il campo non deve essere vuoto/Field cannot be empty"
    ],
    emailRules: [
      v => !!v || "E-mail è obbligatoria/Email is mandatory",
      v =>
        /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/.test(v) ||
        "E-mail deve essere valida/Must be a valid email"
    ],
    fileRules: [
      value =>
        !value ||
        value.size < 10000000 ||
        "L'allegato non può superare i 10 MB!"
    ],
    loadingFields: true,
    requestModelFields: [],
    requestModelData: {},
    requestModelTypes: {},
    attachments: [],
    snackbar: false,
    text: "Errore validazione form/Form validation error",
    timeout: 2000
  })
};
</script>

<style scoped></style>
